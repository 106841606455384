<template>
	<div id="empresas">
		<div class="tabela-empresas">
			<div class="col-12 nova-empresa">
				<v-btn class="primary-button" raised @click="createEmpresa()">
					<i class="fas fa-store"></i>
					<i class="mr-2 fas fa-plus-circle"></i> 
					Cadastrar Empresa
				</v-btn>
			</div>
			<CustomTable 
				v-if="headers != ''"
				:action="'getEmpresas'"
				:getter="$store.getters.empresas"
				:headers="headers"
				:search="true"
				:title="'Empresas'"
				:icone="'fas fa-city'"
				:pagination="true"
				:filters="filtros"
				ref="tabela"
			>
				<template v-slot:cnpj="{ item }">
					{{ formatCnpjCpf(item.cnpj) }}
				</template>
				<template v-slot:status="{ item }">
					<span v-if="item.status">
						Ativo
						<img src="./../../assets/images/icon-ativo.png">
					</span>
					<span v-else>
						Inativo
						<img src="./../../assets/images/icon-inativo.png">
					</span>
				</template>
				<template v-slot:acoes="{ item }">
					<v-btn class="primary-button" raised small @click="editarEmpresa(item.id)">
						<i class="fas fa-cog"></i>
						Editar
					</v-btn>
				</template>
			</CustomTable>
			<v-dialog v-model="dialog_empresa" persistent max-width="1000px">
				<v-card>
					<v-card-title>
						<span v-if="empresa.empresa_id" class="headline"> <img src="./../../assets/images/icone-nova-empresa.png"> Editar Empresa</span>						
						<span v-else class="headline"><img src="./../../assets/images/icone-nova-empresa.png"> Nova Empresa</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-form ref="form_empresa">
								<div class="row">
									<div class="col-6">
										<v-text-field 
											:rules="[v => !!v || 'Campo Nome obrigatório']" 
											v-model="empresa.nome" 
											label="Nome" 
											placeholder="Nome" 
											background-color="white"
											hide-details
											outlined
										/>
									</div>
									<div class="col-6">
										<v-text-field 
											v-mask="'##.###.###/####-##'"
											type="text"
											:rules="[v => (validarCNPJ(v)) || 'Campo CNPJ obrigatório']" 
											v-model="empresa.cnpj" 
											label="CNPJ" 
											placeholder="CNPJ" 
											background-color="white"
											hide-details
											outlined
										/>
									</div>
									<div class="col-6">
										<v-select
											:rules="[v => !!v || 'Campo assumir automático obrigatório']"
											:items="['Sim', 'Não']"
											v-model="empresa.take_automatic"
											label="Assumir Automaticamente"
											placeholder="Assumir Automaticamente"
											background-color="white"
											hide-details
											outlined
										/>
									</div>
									<div  class="col-6">
										<v-select 
											:rules="[v => !!v || 'Campo Respeitas as regras de privacidade do WhatsApp obrigatório']" 
											:items="['Ativo', 'Inativo']" 
											v-model="empresa.privacy_whatsapp" 
											label="Respeitas as regras de privacidade do WhatsApp" 
											placeholder="Respeitas as regras de privacidade do WhatsApp" 
											background-color="white"
											hide-details
											outlined
										/>
									</div>
									<div v-if="empresa.empresa_id"  class="col-4">
										<v-select 
											:rules="[v => !!v || 'Campo Status obrigatório']" 
											:items="['Ativo', 'Inativo']" 
											v-model="empresa.status" 
											label="Status" 
											placeholder="Status" 
											background-color="white"
											hide-details
											outlined
										/>
									</div>
									<div v-if="empresa.empresa_id"  class="col-4">
										<v-text-field 
											v-model="empresa.inatividade" 
											label="Tempo de Inatividade" 
											placeholder="Tempo de Inatividade" 
											background-color="white"
											hide-details
											outlined
										/>
									</div>
									<div v-if="empresa.empresa_id"  class="col-4">
										<v-select 
											:items="departamentos" 
                                            item-text="name"
                                            item-value="id"
											v-model="empresa.department_id" 
											label="Departamento Transf." 
											placeholder="Departamento Transf." 
											background-color="white"
                                            hide-details
                                            outlined
                                            clearable
										/>
									</div>
									<div v-if="empresa.empresa_id"  class="col-12">
										<v-textarea
											v-model="empresa.mensagem_inatividade"
											label="Mensagem de Fechamento da sala por Inatividade"
											placeholder="Mensagem de Fechamento da sala por Inatividade"
											background-color="white"										
											outlined
										/>
									</div>

									<div class="col-12">
										<h3>Horários de Atendimento</h3>
										<div class="horario" v-for="(horario, i) in empresa.horarios" :key="i">
											<div class="inputs">
												<div class="row">
													<div class="col-md-4 col-12">
														<v-select 
															:rules="[v => (!!v || v == 0) || 'Campo Dia obrigatório']" 
															:items="dias_semana" 
															v-model="empresa.horarios[i].weekday" 
															item-text="texto" 
															item-value="dia"
															label="Dia da semana" 
															placeholder="Dia da semana" 
															background-color="white"
															hide-details
															outlined
														/>
													</div>
													<div class="col-md-4 col-12">
														<v-text-field
															:rules="[v => !!v || 'Campo De obrigatório']"
															v-model="empresa.horarios[i].start_time"
															label="De"
															placeholder="De"
															background-color="white"
															hide-details
															outlined
															readonly
															@click="chamaTimePicker"
															:data-modalToggle="'id_modal_time_picker_de_'+[i]"
														/>
														<div :id="'id_modal_time_picker_de_'+[i]" class="modal-time-picker">
															<div class="wrapper-time-picker">
																<v-time-picker
																	v-model="timeDe"
																	full-width
																	format="24hr"
																>
																	<v-spacer></v-spacer>
																	<v-btn text style="text-transform: capitalize; font-size: 16px;" @click="fechaTimePicker()">
																		Cancelar
																	</v-btn>
																	<v-btn class="primary-button" small @click="saveDe(i, timeDe)">
																		OK
																	</v-btn>
																</v-time-picker>
															</div>
														</div>
														<!-- <v-dialog
															ref="modal_time_de"
															v-model="modal_time_de"
															persistent
															width="290px"
														>
															<template v-slot:activator="{ on, attrs }">
																<v-text-field
																	:rules="[v => !!v || 'Campo De obrigatório']" 
																	v-model="empresa.horarios[i].start_time"
																	label="Abre à(s)" 
																	placeholder="Abre à(s)" 
																	background-color="white"
																	hide-details
																	outlined
																	readonly
																	v-bind="attrs"
																	v-on="on"
																/>{{i}}
															</template>
															<v-time-picker 
																v-model="timeDe"
																v-if="modal_time_de" 
																full-width
																format="24hr"
															>
																<v-spacer></v-spacer>
																<v-btn text style="text-transform: capitalize; font-size: 16px;" color="primary" @click="modal_time_de = false">
																	Cancelar
																</v-btn>
																<v-btn color="primary" @click="saveDe(i, timeDe)">
																	OK
																</v-btn>
															</v-time-picker>
														</v-dialog> -->
													</div>
													<div class="col-md-4 col-12">
														<v-text-field
															:rules="[v => !!v || 'Campo Até obrigatório', v => v > empresa.horarios[i].start_time || 'Campo Maior que TimeDe']"
															v-model="empresa.horarios[i].end_time"
															label="Até"
															placeholder="Até"
															background-color="white"
															hide-details 
															outlined
															readonly
															@click="chamaTimePicker"
															:data-modalToggle="'id_modal_time_picker_ate_'+[i]"
														/>
														<div :id="'id_modal_time_picker_ate_'+[i]" class="modal-time-picker">
															<div class="wrapper-time-picker">
																<v-time-picker
																	v-model="timeAte"
																	full-width
																	format="24hr"
																>
																	<v-spacer></v-spacer>
																	<v-btn text style="text-transform: capitalize; font-size: 16px;" @click="fechaTimePicker()">
																		Cancelar
																	</v-btn>
																	<v-btn class="primary-button" small @click="saveAte(i, timeAte)">
																		OK
																	</v-btn>
																</v-time-picker>
															</div>
														</div>
														<!-- <v-dialog
															ref="modal_time_de"
															v-model="modal_time_ate"
															persistent
															width="290px"
														>
															<template v-slot:activator="{ on, attrs }">
																<v-text-field
																	:rules="[v => !!v || 'Campo Até obrigatório']" 
																	v-model="empresa.horarios[i].end_time"
																	label="Fecha à(s)" 
																	placeholder="Fecha à(s)" 
																	background-color="white"
																	hide-details
																	outlined
																	readonly
																	v-bind="attrs"
																	v-on="on"
																/>{{i}}
															</template>
															<v-time-picker 
																v-model="timeAte"
																v-if="modal_time_ate" 
																full-width
																format="24hr"
															>
																<v-spacer></v-spacer>
																<v-btn text style="text-transform: capitalize; font-size: 16px;" color="primary" @click="modal_time_ate = false">
																	Cancelar
																</v-btn>
																<v-btn color="primary" @click="saveAte(i, timeAte)">
																	OK {{i}}
																</v-btn>
															</v-time-picker>
														</v-dialog> -->
													</div>
												</div>
											</div>
											<v-btn v-if="empresa.horarios.length > 1" type="button" @click="removeHorario(i)" color="primary" raised class="remover-horario">
												<i class="fas fa-minus"></i>
											</v-btn>
										</div>
										<v-btn class="primary-button" raised @click="addHorario">
											<i style="margin-right: 10px" class="fas fa-plus"></i> Adicionar Horário
										</v-btn>
									</div>
								</div>
							</v-form>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="closeempresa">
							Cancelar
						</v-btn>
						<v-btn class="primary-button" small @click="enviarempresa">
							Salvar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog v-model="dialog_config_menu" persistent max-width="1000px">
				<v-card>
					<v-card-title>
						<span class="headline"> <img class="btn-edit" style="margin-right: 10px;" src="./../../assets/images/icon-edit-menu.png">Editar Configurações do Menu</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-form ref="form_empresa">
								<div class="row">
									<div class="col-12">
										Tags disponíveis:<br>
										{nome_informado} Nome informado pelo cliente na conversa.
									</div>
									<div class="col-6">
										<v-textarea
											:rules="[v => !!v || 'Campo Mensagem do menu, parte superior obrigatório']" 
											clearable
											clear-icon="mdi-close-circle"
											v-model="config_menu.mensagem_menu_superior" 
											label="Mensagem do menu (Parte superior)" 
											placeholder="Mensagem do menu (Parte superior)" 
											background-color="white"
											hide-details
											outlined
										></v-textarea>
									</div>

									<div class="col-6">
										<v-textarea
											clearable
											clear-icon="mdi-close-circle"
											v-model="config_menu.mensagem_menu_inferior" 
											label="Mensagem do menu (Parte inferior)" 
											placeholder="Mensagem do menu (Parte inferior)" 
											background-color="white"
											hide-details
											outlined
										></v-textarea>
									</div>

									<div class="col-6">
										<v-textarea
											:rules="[v => !!v || 'Campo Mensagem de opção inválida do menu, parte superior obrigatório']" 
											clearable
											clear-icon="mdi-close-circle"
											v-model="config_menu.mensagem_menu_opcao_invalida_superior" 
											label="Mensagem de opção inválida do menu (Parte superior)" 
											placeholder="Mensagem de opção inválida do menu (Parte superior)" 
											background-color="white"
											hide-details
											outlined
										></v-textarea>
									</div>

									<div class="col-6">
										<v-textarea
											clearable
											clear-icon="mdi-close-circle"
											v-model="config_menu.mensagem_menu_opcao_invalida_inferior" 
											label="Mensagem de opção inválida do menu (Parte inferior)" 
											placeholder="Mensagem de opção inválida do menu (Parte inferior)" 
											background-color="white"
											hide-details
											outlined
										></v-textarea>
									</div>
								</div>
							</v-form>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="closeconfigmenu">
							Cancelar
						</v-btn>
						<v-btn class="primary-button" small @click="enviarconfigmenu">
							Salvar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog v-model="dialog_config_nome" persistent max-width="1000px">
				<v-card>
					<v-card-title>
						<span class="headline"> <img class="btn-edit" style="margin-right: 10px;" src="./../../assets/images/icon-edit-nome.png"> Editar Configurações do Nome</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-form ref="form_empresa">
								<div class="row">
									<div class="col-12">
										Tags disponíveis:<br>
										{nome_informado} Nome informado pelo cliente na conversa.(Utilizado somente para confirmar nome)
									</div>
									<div class="col-6">
										<v-select 
											:items="[{id:true,nome:'Sim'}, {id:false,nome:'Não'}]" 
											item-text="nome" 
											item-value="id"
											v-model="config_nome.pergunta_nome" 
											label="Perguntar nome do Cliente?" 
											placeholder="Perguntar nome do Cliente?" 
											background-color="white"
											hide-details
											outlined
										/>
									</div>

									<div class="col-6">
										<v-select 
											:items="[{id:true,nome:'Sim'}, {id:false,nome:'Não'}]" 
											item-text="nome" 
											item-value="id"
											v-model="config_nome.confirma_nome" 
											label="Confirmar nome do Cliente?" 
											placeholder="Confirmar nome do Cliente?" 
											background-color="white"
											hide-details
											outlined
										/>
									</div>

									<div class="col-6">
										<v-textarea
											clearable
											clear-icon="mdi-close-circle"
											v-model="config_nome.mensagem_pergunta" 
											label="Mensagem (Perguntar nome)" 
											placeholder="Mensagem (Perguntar nome)" 
											background-color="white"
											hide-details
											outlined
										></v-textarea>
									</div>

									<div class="col-6">
										<v-textarea
											clearable
											clear-icon="mdi-close-circle"
											v-model="config_nome.mensagem_confirmar_nome" 
											label="Mensagem (Confirmar nome)" 
											placeholder="Mensagem (Confirmar nome)" 
											background-color="white"
											hide-details
											outlined
										></v-textarea>
									</div>
								</div>
							</v-form>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="closeconfignome">
							Cancelar
						</v-btn>
						<v-btn class="primary-button" small @click="enviarconfignome">
							Salvar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
			<Loader v-if="loading"/>
		</div>
	</div>
</template>

<script>
	// importa o store
	import store from '@/store'
	// importa o componente de DialogMensagem 
	import DialogMensagem from '@/components/DialogMensagem.vue'
	// importa o componente de Loader 
	import Loader from '@/components/Loader.vue'
	// importa o componente de CustomTable 
	import CustomTable from '@/components/CustomTable.vue'

	// exporta o componente
	export default {
		// nome do componente
		name: 'empresas',
		// componentes filhos
		components: {
			// componente de DialogMensagem
			DialogMensagem,
			// componente de CustomTable
			CustomTable,
			// componente de Loader
			Loader,
		},
		// dados do componente
		data: () => ({
			// variável para mostrar a modal para editar/criar um empresa
			dialog_empresa: false,
			// variável para mostrar a modal para configuracoes de menu da empresa
			dialog_config_menu: false,
			// variável para mostrar a modal para configuracoes de menu da empresa
			dialog_config_nome: false,
			// variável para mostrar a modal de informação
			dialog_resposta: false,
			// variável para mostrar a modal de timer picker de
			modal_time_de: false,
			// variável para o horario de da modal de time picker
			timeDe: null,
			// variável para mostrar a modal de timer picker até
			modal_time_ate: false,
			// variável para o horario até da modal de time picker
			timeAte: null,
			// variável para a mensagem de resposta
			resposta: {},
			// variável para o loading
			loading: false,
			// variável para os dias da semana
			dias_semana: [
				{
					dia: 0, 
					texto: 'Domingo',
				},
				{
					dia: 1, 
					texto: 'Segunda-feira',
				},
				{
					dia: 2, 
					texto: 'Terça-feira',
				},
				{
					dia: 3, 
					texto: 'Quarta-feira',
				},
				{
					dia: 4, 
					texto: 'Quinta-feira',
				},
				{
					dia: 5, 
					texto: 'Sexta-feira',
				},
				{
					dia: 6, 
					texto: 'Sábado',
				},
			],
			// variável para os departamentos
			departamentos: [],
			// variável para criar/editar empresa
			empresa: {
				empresa_id: '',
				nome: '',
				cnpj: '',
				take_automatic: '',
				status: '',
				privacy_whatsapp: '',
				inatividade: '',
				mensagem_inatividade: '',
				department_id: '',
				texto_horario_atendimento_indisponivel:'',
				horarios: [{
					id: '',
					weekday: '',
					start_time: '',
					end_time: '',
				}]
			},
			// variável para o cabeçalho da tabela
			headers: [
				{
					value: 'name',
					text: 'Nome',
					sortable: true,
				},
				{
					value: 'cnpj',
					text: 'CNPJ',
					sortable: true,
				},
				{
					value: 'token',
					text: 'Token',
					sortable: true,
				},
				{
					value: 'status',
					text: 'Status',
					sortable: true,
				},
				{
					value: 'acoes',
					sortable: false,
					text: 'Ações',
				},
				// {
				// 	value: 'config_menu',
				// 	sortable: false,
				// 	text: 'Config. Menu',
				// },
				// {
				// 	value: 'config_nome',
				// 	sortable: false,
				// 	text: 'Config. Nome',
				// },
			],
			config_menu:{
				empresa:'',
                mensagem_menu_superior: '',
                mensagem_menu_inferior: '',
                mensagem_menu_opcao_invalida_superior: '',
                mensagem_menu_opcao_invalida_inferior: ''
			},
			config_nome:{
				empresa:'',
                mensagem_pergunta: '',
                mensagem_confirmar_nome: '',
                pergunta_nome: true,
                confirma_nome: true
			},
			// variável para os filtros da tabela
			filtros: {
				perPage: 20,
			},
		}),
		// funções deste componente
		methods: {
			addHorario(){
				this.empresa.horarios.push({
					id: '',
					weekday: '',
					start_time: '',
					end_time: '',
				})
			},
			removeHorario(index){
				if (index > -1) {
					this.empresa.horarios.splice(index, 1);
				}
			},
			async saveDe(i, time){
				this.empresa.horarios[i].start_time = await time
				// this.modal_time_de = false
                let el = document.getElementById(this.attrDataIrmaoModalToggle)
                el.classList.remove('ativo') 
                this.attrDataIrmaoModalToggle.classList.remove('ativo')
			},
			async saveAte(i, time){
				this.empresa.horarios[i].end_time = await time
				// this.modal_time_ate = false
                let el = document.getElementById(this.attrDataIrmaoModalToggle)
                el.classList.remove('ativo')
                this.attrDataIrmaoModalToggle.classList.remove('ativo')
			},

			// função para abrir a modal de cadastro/edição de uma empresa
			async createEmpresa(){
				// coloca o componente como loading
				this.loading = true
				// caso a empresa já exista
				if(this.empresa.empresa_id){
					// chamamos a função para buscar seus departamentos
					await this.departamentosEmpresa()
				}
				// mostramos a modal 
				this.dialog_empresa = true
				// retiramos o loading do componente
				this.loading = false
			},

			// função para pegar os departamentos de uma empresa
			async departamentosEmpresa(){
                // faz um dispatch para uma action no vuex para pegar os departamentos
				var resp = await store.dispatch('departmentCompany', this.empresa.empresa_id)
                // caso o status seja 200 (deu certo)
                if(resp.status == 200){
                    // atribui os dados à variável local
                    this.departamentos = resp.data.departamentos
                }
			},

			// função para enviar um empresa
			async enviarempresa(){
				let resp = {}
				// caso os campos do formulário estejam válidos
				if (this.$refs.form_empresa.validate()){
					// coloca o componente como loading
					this.loading = await true
					// coloeta os dados do empresa
					let dados = await {
						// coleta o nome do empresa
						name: await this.empresa.nome,
						// coleta o cnpj do empresa
						cnpj: await this.empresa.cnpj.replace(/\D/g, ""),
						// coleta a preferencia de assumir da empresa
						take_automatic: await this.empresa.take_automatic == 'Sim' ? true : false,
						// seta o status como ativo inicialmente
						status: true,
						// coleta o tempo de inatividade configurado
						downtime: this.empresa.inatividade,
						// coleta o departamento selecionado
						department_id: this.empresa.department_id,
						// coleta a mensagem de fechamento da sala configurada
						message_of_downtime: this.empresa.mensagem_inatividade,
						// coleta as configurações de privacidade da empresa
						privacy_whatsapp: await this.empresa.privacy_whatsapp == 'Ativo' ? true : false,
						// coleta os horários de atendimento da empresa
						hours: await this.empresa.horarios,
					}
					// caso exista um empresa_id 
					if(this.empresa.empresa_id){
						// coleta o status do empresa
						dados.status = await this.empresa.status == 'Ativo' ? true : false
						// armazena os dados para a atualização
						let date_update = await {
							dados_empresa: dados,
							id: this.empresa.empresa_id
						}
						// rota para a atualização dos dados da empresa
						resp = await store.dispatch('putEmpresas', date_update)
					}else{
						// rota para a criação de uma empresa
						resp = await store.dispatch('postEmpresas', dados)
					}
					// caso tenha ocorrido algum erro
					if(resp.status != 200 && resp.status != 201){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.error
						// mostra a mensagem
						this.dialog_resposta = true
					// caso tenha dado tudo certo
					}else{
						// atribui o título da mensagem 
						this.resposta.titulo = 'Empresa '  +  (this.empresa.empresa_id ? 'editada!' : 'cadastrada!')
						// atribui o corpo da mensagem 
						this.resposta.mensagem = 'Empresa ' + (this.empresa.empresa_id ? 'editada' : 'cadastrada') + ' com sucesso!'
						// fecha a modal
						this.closeempresa()
						// mostra a mensagem
						this.dialog_resposta = true
					}
					// retira o loading do componente
					this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
				}
			},
			// função para enviar um empresa
			async enviarconfigmenu(){
				// caso os campos do formulário estejam válidos
				if (this.$refs.form_empresa.validate()){
					// coloca o componente como loading
					this.loading = await true
					let dados = await {
						mensagem_menu_superior: await this.config_menu.mensagem_menu_superior,
						mensagem_menu_inferior: await this.config_menu.mensagem_menu_inferior,
						mensagem_menu_opcao_invalida_superior: await this.config_menu.mensagem_menu_opcao_invalida_superior,
						mensagem_menu_opcao_invalida_inferior: await this.config_menu.mensagem_menu_opcao_invalida_inferior,
					}

					// caso exista um empresa 
					if(this.config_menu.empresa){
						// coleta o id do empresa
						dados.empresa = await this.config_menu.empresa
					}
					// faz a requisição para o back para coletar os grupos
					var resp = await store.dispatch('postConfigMenuEmpresa', dados)
					// caso o status da resposta seja 200 (deu certo)
					if(resp.status != 200 || !resp.data.sucesso){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.error.join('\n')
						// mostra a mensagem
						this.dialog_resposta = true
					// caso tenha dado algum erro
					}else{
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Configuração de Menu '  +  (this.config_menu.empresa ? 'editada!' : 'cadastrada!')
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await 'Configuração de Menu ' + (this.config_menu.empresa ? 'editada' : 'cadastrada') + ' com sucesso!'
						// mostra a mensagem
						this.closeconfigmenu()
						// fecha a modal de create/edit empresa
						this.dialog_resposta = true
					}
					// retira o loading do componente
					this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
				}
			},
			// função para enviar um empresa
			async enviarconfignome(){
				// caso os campos do formulário estejam válidos
				if (this.$refs.form_empresa.validate()){
					// coloca o componente como loading
					this.loading = await true
					let dados = this.config_nome
					
					dados.pergunta_nome = await dados.pergunta_nome ? true : false
					dados.confirma_nome = await dados.confirma_nome ? true : false

					// faz a requisição para o back para coletar os grupos
					var resp = await store.dispatch('postConfigNomeEmpresa', dados)
					// caso o status da resposta seja 200 (deu certo)
					if(resp.status != 200 || !resp.data.sucesso){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.error.join('\n')
						// mostra a mensagem
						this.dialog_resposta = true
					// caso tenha dado algum erro
					}else{
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Configuração de Nome '  +  (this.config_nome.empresa ? 'editada!' : 'cadastrada!')
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await 'Configuração de Nome ' + (this.config_nome.empresa ? 'editada' : 'cadastrada') + ' com sucesso!'
						// mostra a mensagem
						this.closeconfignome()
						// fecha a modal de create/edit empresa
						this.dialog_resposta = true
					}
					// retira o loading do componente
					this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
				}
			},
			// função para coletar um empresa para editar
			async editarConfigMenu(empresa_id){
				this.config_menu.empresa = empresa_id
				// coloca o componente como loading
				this.loading = true
				// faz um dispatch para uma action no vuex store para pegar um empresa passando o id 
				var resp = await store.dispatch('getConfigMenuEmpresa', empresa_id)
				// caso o status da resposta seja 200 (deu certo) 
				if(resp.status == 200){
					// atribui os dados do empresa vindos do back à váriável local
					this.config_menu.mensagem_menu_superior = await resp.data.mensagem_menu_superior || ''
					this.config_menu.mensagem_menu_inferior = await resp.data.mensagem_menu_inferior || ''
					this.config_menu.mensagem_menu_opcao_invalida_superior = await resp.data.mensagem_menu_opcao_invalida_superior || ''
					this.config_menu.mensagem_menu_opcao_invalida_inferior = await resp.data.mensagem_menu_opcao_invalida_inferior || ''
					// mostra a modal de creat/edit empresa
					this.dialog_config_menu = true
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
				// atualiza a tabela
				this.$refs.tabela.init()
			},
			// função para coletar um empresa para editar
			async editarConfigNome(empresa_id){
				this.config_nome.empresa = empresa_id
				// coloca o componente como loading
				this.loading = true
				// faz um dispatch para uma action no vuex store para pegar um empresa passando o id 
				var resp = await store.dispatch('getConfigNomeEmpresa', empresa_id)
				// caso o status da resposta seja 200 (deu certo) 
				if(resp.status == 200){
					// atribui os dados do empresa vindos do back à váriável local
					this.config_nome.mensagem_pergunta = await resp.data.mensagem_pergunta || ''
					this.config_nome.pergunta_nome = await resp.data.pergunta_nome ? true : false
					this.config_nome.confirma_nome = await resp.data.confirma_nome ? true : false
					this.config_nome.mensagem_confirmar_nome = await resp.data.mensagem_confirmar_nome || ''
					// mostra a modal de creat/edit empresa
					this.dialog_config_nome = true
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
				// atualiza a tabela
				this.$refs.tabela.init()
			},
			// função para coletar um empresa para editar
			async editarEmpresa(empresa_id){
				// coloca o componente como loading
				this.loading = true
				// faz um dispatch para uma action no vuex store para pegar um empresa passando o id 
				var resp = await store.dispatch('getEmpresa', empresa_id)
				// caso o status da resposta seja 200 (deu certo) 
				if(resp.status == 200){
					// atribui os dados do empresa vindos do back à váriável local
					this.empresa.empresa_id = await resp.data.id || ''
					this.empresa.nome = await resp.data.name || ''
					this.empresa.cnpj = await resp.data.cnpj || ''
					this.empresa.inatividade = await resp.data.downtime || ''
					this.empresa.department_id = await parseInt(resp.data.department_id) || ''
					this.empresa.mensagem_inatividade = await resp.data.message_of_downtime || ''
					this.empresa.take_automatic = await resp.data.take_automatic ? 'Sim' : 'Não'
					this.empresa.status = await resp.data.status ? 'Ativo' : 'Inativo'
					this.empresa.privacy_whatsapp = await resp.data.privacy_whatsapp ? 'Ativo' : 'Inativo'
					if(resp.data.hours){ 
						for(let i = 0; i < resp.data.hours.length; i++){
							if(this.empresa.horarios[i] == undefined){
								this.empresa.horarios[i] = {
								}
							}
							this.empresa.horarios[i].id = resp.data.hours[i].id
							this.empresa.horarios[i].weekday = resp.data.hours[i].weekday
							this.empresa.horarios[i].start_time = resp.data.hours[i].start_time
							this.empresa.horarios[i].end_time = resp.data.hours[i].end_time
						}
					}else{
						this.empresa.horarios = [{
							id: '',
							weekday: '',
							start_time: '',
							end_time: '',
						}]
					}
					// abre a modal
					this.createEmpresa()
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
				// atualiza a tabela
				this.$refs.tabela.init()
			},
			// função que roda quando é fechada a modal de create/edit empresa
			closeempresa(){
				// fecha a modal
				this.dialog_empresa = false
				// limpa os departamentos
				this.departamentos = []
				// limpa os dados locais do empresa
				this.empresa =  {
					empresa_id: '',
					nome: '',
					cnpj: '',
					take_automatic: '',
					status: '',
					inatividade: '',
					department_id: '',
					mensagem_inatividade: '',
					texto_horario_atendimento_indisponivel:'',
					horarios: [{
						id: '',
						weekday: '',
						start_time: '',
						end_time: '',
					}]
				}
			},
			// função que roda quando é fechada a modal de create/edit empresa
			closeconfigmenu(){
				// fecha a modal
				this.dialog_config_menu = false
				// limpa os dados locais do empresa
				this.config_menu.empresa = ''
				this.config_menu.mensagem_menu_superior = ''
				this.config_menu.mensagem_menu_inferior = ''
				this.config_menu.mensagem_menu_opcao_invalida_superior = ''
				this.config_menu.mensagem_menu_opcao_invalida_inferior = ''
			},
			// função que roda quando é fechada a modal de create/edit empresa
			closeconfignome(){
				// fecha a modal
				this.dialog_config_nome = false
				// limpa os dados locais do empresa
				this.config_nome.mensagem_pergunta = ''
				this.config_nome.pergunta_nome = true
				this.config_nome.confirma_nome = true
				this.config_nome.mensagem_confirmar_nome = ''
			},
			formatCnpjCpf(value){
				const cnpjCpf = value.replace(/\D/g, '');
			
				// if (cnpjCpf.length === 11) {
				// 	return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
				// } 
			
				return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
			},
			validarCNPJ(value) {
				var cnpj = value.replace(/[^\d]+/g,'');
				if(cnpj == '') return false;
				if (cnpj.length != 14){
					return false;
				}
				// Elimina CNPJs invalidos conhecidos
				if (cnpj == "00000000000000" || 
					cnpj == "11111111111111" || 
					cnpj == "22222222222222" || 
					cnpj == "33333333333333" || 
					cnpj == "44444444444444" || 
					cnpj == "55555555555555" || 
					cnpj == "66666666666666" || 
					cnpj == "77777777777777" || 
					cnpj == "88888888888888" || 
					cnpj == "99999999999999"){
					return false;
				}
				// Valida DVs
				var tamanho = cnpj.length - 2
				var numeros = cnpj.substring(0,tamanho);
				var digitos = cnpj.substring(tamanho);
				var soma = 0;
				var pos = tamanho - 7;
				for (let i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2)
						pos = 9;
				}
				resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
				if (resultado != digitos.charAt(0))
					return false;
					
				tamanho = tamanho + 1;
				numeros = cnpj.substring(0,tamanho);
				soma = 0;
				pos = tamanho - 7;
				for (let i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2)
						pos = 9;
				}
				var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
				if (resultado != digitos.charAt(1))
					return false;
					
				return true;
				
			},
			chamaTimePicker: function(e){
                let var_el = e.target
                let data_modal_toggle = var_el.getAttribute('data-modalToggle')
                this.attrDataIrmaoModalToggle = data_modal_toggle
                let el_modal = document.getElementById(this.attrDataIrmaoModalToggle)
                el_modal.classList.add('ativo')
            },
            fechaTimePicker(){
                let el = document.getElementById(this.attrDataIrmaoModalToggle)
                el.classList.remove('ativo')
            },
		},
	}
</script>

<style lang="scss" scoped>
	#empresas{
		display: flex;
		max-height: 100%;
		padding: 24px;
		flex-wrap: wrap;
		.tabela-empresas{
			width: 100%;
			background-color: #fff;
			border-radius: 10px;
			.nova-empresa{
				display: flex;
				justify-content: flex-end;
				padding-bottom: 0;
				button{
					padding: 0 5px !important;
					i, svg{
						margin-left: 10px;
					}
					text-transform: capitalize;
				}
			}
		}
	}
	.horario{
		display: flex;
		flex: 1 1 auto;
		.inputs{
			display: flex;
			flex-wrap: wrap;
			flex: 1 1 auto;
			.modal-time-picker{
				display: none;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(33, 33, 33, 0.3);
				border-color: rgb(33, 33, 33);
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				z-index: -1;
				&.ativo{
					display: flex;
					z-index: 2;
				}
				.wrapper-time-picker{
					width: 300px;
				}
			}
		}
		.remover-horario{
			min-width: inherit;
			margin-top: 20px;
			margin-left: 24px;
		}
	}
	.v-dialog{
		.v-card{
			background-color: #f9f9f9;
		}
		.headline{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-weight: 600;
			color: #11263C;
		}
	}
</style>